<template>
  <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="blue darken-3"
  >
    <v-app-bar-nav-icon @click.stop="setDrawer(!drawer)">
      <v-avatar
          size="32px"
          item
          tile
      >
        <v-img
            src="@/assets/logo.svg"
            alt="C N M"
        />
      </v-avatar>
    </v-app-bar-nav-icon>


    <v-spacer/>
    <div class="hidden-sm-and-down">
    <search-filter />
    </div>
    <div class="hidden-md-and-up">

    <search-menu />
    </div>
    <div v-if="inbox.length > 0">
      <v-tooltip left bottom nudge-left="10" offset-overflow>
        <template #activator="{ on }">
          <v-btn
              icon
              v-on="on"
              @click="processInbox"
          >
            <v-icon>mdi-bell</v-icon>
            <v-badge
                color="green"
                :content="inbox.length"
            />
          </v-btn>
        </template>
        <span><Inbox/></span>
      </v-tooltip>
    </div>
    <v-btn icon @click="$vuetify.theme.dark = !$vuetify.theme.dark">
      <v-icon>
        {{ !this.$vuetify.theme.dark ? 'mdi-lightbulb' : 'mdi-lightbulb-on' }}
      </v-icon>
    </v-btn>
    <v-btn
        :key="`logout-header-link`"
        color="white"
        text
        rounded
        icon
        @click="logout"
    >
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import {mapState, mapMutations, mapActions, mapGetters} from "vuex";
import Inbox from "@/components/conversations/Inbox.vue";
import SearchMenu from "@/components/core/SearchMenu";
import SearchFilter from "@/components/search/SearchFilter";

export default {
  components: {
    SearchFilter,
    Inbox,
    SearchMenu,
  },



  methods: {
    ...mapActions("conversations", ["search", "processInbox"]),
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),

    logout() {
      this.$store.dispatch("logout");
    },
    goHome() {
      this.$router.push({name: "conversations"});
    },

  },
  computed: {
    ...mapState(["drawer"]),
    ...mapState("conversations", ["inbox"]),

  },
};
</script>

<style></style>
