<template>
  <v-card>
    <v-row
        no-gutters
        justify="space-around"
        align="center"
    >

      <v-col cols="12" md="2">
        <v-select
            :value="conversationStage"
            @input="updateConversationStage"
            :items="conversationStages"
            label="Stage"
        />
      </v-col>
      <v-col cols="12" md="4" class="d-flex justify-end">

          <v-btn large icon @click="handleDateAddDays(-1)">
            <v-icon>mdi-arrow-left-bold-circle-outline</v-icon>
          </v-btn>
          <v-menu
              v-model="calendar"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="50"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                  :value="currentDate.toISOString().substring(0, 10)"
                  @change="handleDateChange"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              />
            </template>
            <v-date-picker :value="currentDate.toISOString()" @change="handleDateChange" no-title scrollable/>
          </v-menu>
          <v-btn large icon class="mr-5" @click="handleDateAddDays(1)">
            <v-icon>mdi-arrow-right-bold-circle-outline</v-icon>
          </v-btn>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
            :value="searchTerm"
            @input="updateSearchTerm"
            label="Keyword"
            class="mx-4"
            clearable
        />
      </v-col>

      <v-col cols="12" md="1">
        <v-btn block @click="handleSearch">
          Search
        </v-btn>
      </v-col>
      <v-col cols="12" md="1">
        <v-btn block @click="clearFilter">
          Clear
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: "SearchFilter",
  computed: {
    ...mapState('search', ['calendar', 'searchTerm', 'conversationStage']),
    ...mapState('conversations', ['conversationStages']),
    ...mapGetters('search', ['getDate']),
    currentDate() {
      return this.getDate(this.view()).date;
    }
  },
  methods: {
    ...mapActions('search', ['search', 'dateAddDays']),
    ...mapMutations('search', ['updateDate', 'clearFilter', 'setSearchTerm', 'setConversationStage']),
    view() {
      return this.$route.name;
    },
    handleDateChange(value) {
      this.updateDate({ value, view: this.view() });
      this.search(this.view());
    },
    handleDateAddDays(days) {
      this.dateAddDays({ days, view: this.view() });
    },
    handleSearch() {
      this.search(this.view());
    },
    updateSearchTerm(value) {
      this.setSearchTerm(value);
    },
    updateConversationStage(value) {
      this.setConversationStage(value);
    }
  },
  watch: {
    "$route.name": {
      handler(value) {
        this.getDate(value);
      }
    }
  }
}
</script>

<style scoped>

</style>
